* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
  background: #fff;
  position: relative;
}

.middledot {
  font-weight: 700;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 20px;
  margin: 15px 0;
}

.item-card-container {
  position: relative;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
}

.item-card-count {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #f9a818;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-card-image-wrapper {
  width: 100%;
  height: 100px;
}

.item-card-image {
  width: 100%;
  height: 100%;
}

.item-card-title {
  flex: 1;
  text-align: center;
}

.item-card-name {
  display: block;
  padding-bottom: 4px;
  font-weight: 500;
}

.item-card-price {
  font-weight: 600;
}

.item-card-button {
  text-transform: uppercase;
  display: block;
  width: 100%;
  height: 35px;
  margin: 0 auto;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  background-color: #f9a818;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.item-card-toggle-qty {
  width: 100%;
  height: 35px;
  display: flex;
  margin: 0 auto;
  gap: 10px;
  color: #fff;
}

.item-card-toggle-qty span {
  flex: 1;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.item-card-toggle-add {
  background-color: #f9a818;
}

.item-card-toggle-remove {
  background-color: #ea4e48;
  padding-bottom: 5px;
}

.cart-container {
  background-color: #f7f7f7;
  height: 100vh;
}

.cart-order-info {
  padding: 15px;
  background-color: #fff;
}

.cart-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.cart-name {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}

.cart-edit-button {
  color: #31b444;
  font-weight: 600;
}

.cart-order-items {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cart-order-item {
  display: flex;
  gap: 10px;
}

.cart-order-item-image {
  width: 50px;
  height: 50px;
}

.cart-order-item-title {
  flex: 1;
  font-weight: 600;
}

.cart-order-item-price {
  font-weight: 600;
}

.cart-total-price {
  margin-top: 10px;
  border-top: 1px solid #77777730;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 600;
}

.cart-order-comment {
  margin: 20px 0;
  width: 100%;
}

.cart-order-comment-input {
  width: 100%;
  padding: 15px 20px;
  border: none;
  font-size: 16px;
  margin-bottom: 15px;
}

.cart-order-telephone {
  border: none;
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
  outline: none;
}

.cart-order-comment-tip {
  padding-left: 20px;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.mask {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .4);
  z-index: 11;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: #fff;
  z-index: 12;
  padding: 15px;
  border-radius: 5px;
}

.modal-item-image {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}

.modal-item-name {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
}

.modal-item-packaging {
  margin: 2px 0;
}

.order-success {
  color: #31b444;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
