.search {
  margin-bottom: 10px;

  &__input {
    background-color: #f7f7f7;
    padding: 7px 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #333;
    width: 300px;
  }
}