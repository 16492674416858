.main {
  padding: 15px;
}

.category {
  display: inline-flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  &__name {
    display: inline-block;
  }

  &__icon {
    font-size: 25px;
    font-weight: 600;
    padding-top: 2px;
  }
}